import React from "react";

export default function Events() {
    return (
        <>
            <h1>Events</h1>
            <h1>Events</h1>
            <h1>Events</h1>
            <h1>Events</h1>
            <h1>Events</h1>
        </>
    )
}