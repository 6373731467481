import React from "react";

export default function Gallery() {
    return (
        <>
            <h1>Gallery</h1>
            <h1>Gallery</h1>
            <h1>Gallery</h1>
            <h1>Gallery</h1>
            <h1>Gallery</h1>
        </>
    )
}